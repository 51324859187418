import React from 'react';
import styled from 'styled-components';
import ButtonHeader from 'components/buttons/ButtonHeaderComponent';
import { FormattedMessage } from 'i18n';
import flexbox from 'styles/utils/flexbox';

function SearchButtonComponent({
  messageSearch,
  onClickSearch,
  onClickClose,
  isAirBaltic,
  disabled,
  styles,
  className,
}) {
  return (
    <Root className={className}>
      <ButtonHeader
        onClick={() => {
          onClickSearch();
          onClickClose && onClickClose();
        }}
        isAirBaltic={isAirBaltic}
        styles={styles}
        disabled={disabled}
      >
        {React.isValidElement(messageSearch) ? messageSearch : <FormattedMessage {...messageSearch} />}
      </ButtonHeader>
    </Root>
  );
}

export default SearchButtonComponent;

const Root = styled.div`
  ${flexbox({})}
`;
