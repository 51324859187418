import React from 'react';
import { connect } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { withContext } from 'i18n/context';
import { searchConditionsActions } from 'state/searchConditions';
import { WAY_LEAVE, WAY_RETURN } from 'constants/calendarHighlights';
import DateRangePickerWrapperComponent from './DateRangePickerWrapperComponent';
import websiteIds from 'constants/website.config';
import { injectIntl } from 'react-intl';
import { SPECIFIC_DATES_CALENDAR, PERIOD_CALENDAR } from './constants/calendarSwitch';
import getFlexibleDaysRange from './utils/getFlexibleDaysRange';
import CalendarDay, { CrossLine } from './components/CalendarDay';
import isDateDisabled from './utils/isDateBlocked';
import isBeforeDay from './utils/isBeforeDay';

class DateRangePickerWrapper extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      firstVisibleMonth: null,
      translations: null,
      minDate: null,
      maxDate: null,
    };

    this.dateRangePickerRef = React.createRef();

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.initialVisibleMonth = this.initialVisibleMonth.bind(this);
    this.isDayHighlighted = this.isDayHighlightedFactory();
    this.isDayBlocked = this.isDayBlockedFactory();
    this.storeFirstVisibleMonth = this.storeFirstVisibleMonth.bind(this);
    this.renderDayContents = this.renderDayContentsFactory();
    this.resetInputFocus = this.resetInputFocus.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
  }

  componentDidMount() {
    this.props.hydrateSelectedDates();
    this.updateSpecialDates();
    this.setCalendarMinMaxDates();
  }

  componentDidUpdate(prevProps) {
    this.updateSpecialDates(prevProps);

    const { selectDates } = this.props;
    const { selectDates: _selectDates } = prevProps;

    if (_selectDates.dates.from !== null && selectDates.dates.from === null) {
      this.storeFirstVisibleMonth(null);
    } else {
      this.evalFirstVisibleMonthFromSelectDates(true);
    }

    if (!this.state.translations && this.props.locale) {
      this.setState({
        translations: {
          startDatePlaceholder: this.props.intl.formatMessage({
            id: 'dates.startDate.placeholder',
            defaultMessage: 'Start date',
          }),
          endDatePlaceholder: this.props.intl.formatMessage({
            id: 'dates.endDate.placeholder',
            defaultMessage: 'End date',
          }),
        },
      });
    }
  }

  handleCloseButtonClick() {
    if (this.dateRangePickerRef.current) {
      this.dateRangePickerRef.current.onOutsideClick();
    }
  }

  setCalendarMinMaxDates() {
    this.setState({
      minDate: moment().add(1, 'day'),
      maxDate: moment().add(1, 'y'),
    });
  }

  updateSpecialDates() {
    this.setState(() => {
      this.isDayHighlighted = this.isDayHighlightedFactory();
      this.isDayBlocked = this.isDayBlockedFactory();
      this.renderDayContents = this.renderDayContentsFactory();
    });
  }

  resetInputFocus(focus) {
    this.setState({
      focusedInput: focus,
    });
  }

  onDatesChange(changedDates) {
    const { selectDates, calendarOption, numberOfDays } = this.props;
    const isStartDate = this.state.focusedInput === 'startDate';

    const isSelectedOutsideRange =
      selectDates.dates.from &&
      selectDates.dates.to &&
      calendarOption === SPECIFIC_DATES_CALENDAR &&
      (moment(changedDates.startDate).isBefore(selectDates.dates.from) ||
        moment(changedDates.endDate).isAfter(selectDates.dates.to));

    const flexibleRange = getFlexibleDaysRange(numberOfDays);
    const isFlexibleDatesInRange =
      changedDates.startDate &&
      selectDates.dates.from &&
      selectDates.dates.to &&
      changedDates.startDate.diff(selectDates.dates.to, 'days') < 0 &&
      changedDates.startDate.diff(selectDates.dates.to, 'days') > -flexibleRange;

    if (isStartDate || isSelectedOutsideRange || isFlexibleDatesInRange) {
      this.props.setCalendarDates({
        startDate: changedDates.startDate,
        endDate: null,
      });
    } else {
      if (isSelectedOutsideRange || isFlexibleDatesInRange) {
        this.props.setCalendarDates({
          startDate: changedDates.startDate,
          endDate: null,
        });
      } else {
        this.props.setCalendarDates(changedDates);
      }
    }
  }

  onFocusChange(focusedInput, matches) {
    const { selectDates, fetchPriceDays } = this.props;

    if (
      matches &&
      ((focusedInput === 'endDate' && selectDates.dates.to !== null) ||
        (focusedInput === 'startDate' && selectDates.dates.from !== null && selectDates.dates.to !== null))
    ) {
      focusedInput = 'startDate';
    } else if (
      matches &&
      focusedInput === 'startDate' &&
      selectDates.dates.from !== null &&
      selectDates.dates.to === null
    ) {
      focusedInput = 'endDate';
    } else {
      if (focusedInput === null) {
        focusedInput = null;
      } else if (
        selectDates.dates.from === null ||
        (selectDates.dates.from !== null && selectDates.dates.to !== null)
      ) {
        focusedInput = 'startDate';
      } else if (this.state.focusedInput === 'startDate' && selectDates.dates.from !== null) {
        focusedInput = 'endDate';
      }
    }

    this.setState({
      focusedInput: focusedInput,
    });

    // TODO: Refactor - Replace string comparisons with constants
    const { firstVisibleMonth } = this.state;

    if (focusedInput === null && selectDates.dates.from !== null) {
      this.setState({
        firstVisibleMonth: selectDates.dates.from,
      });
    } else if (focusedInput !== null && firstVisibleMonth === null) {
      this.evalFirstVisibleMonthFromSelectDates(false);
    }

    if (this.props.tooltipIsVisible) {
      this.props.setMissingValuesForInput();
    }

    fetchPriceDays && fetchPriceDays(selectDates, focusedInput === 'endDate');
  }

  evalFirstVisibleMonthFromSelectDates(excludeDefault) {
    const { selectDates } = this.props;
    const { firstVisibleMonth } = this.state;

    if (firstVisibleMonth === null) {
      if (selectDates.dates.from !== null) {
        this.storeFirstVisibleMonth(selectDates.dates.from);
      } else if (selectDates.initialVisibleMonth.leave !== null) {
        this.storeFirstVisibleMonth(selectDates.initialVisibleMonth.leave);
      } else if (!excludeDefault) {
        this.storeFirstVisibleMonth(moment());
      }
    }
  }

  storeFirstVisibleMonth(firstVisibleMonth) {
    this.setState({ firstVisibleMonth });
  }

  // Updating the props will not update highlighted days, outside range days, disabled days etc.
  // because of internal optimizations in react-dates.
  // They get updated only onFocusChange or onDatesChange.
  // That's why we are using a factory which returns a function. This might have some performace hit.
  isDayHighlightedFactory() {
    return (day) => {
      const { renderDayContents, selectDates, numberOfDays, calendarOption } = this.props;
      const { focusedInput } = this.state;

      if (_isEmpty(this.props.selectDates.calendarHighlightsForReactDates)) {
        return false;
      }
      if (renderDayContents) {
        return false;
      }
      const highlights = this.props.selectDates.calendarHighlightsForReactDates;
      const way =
        focusedInput === 'startDate' || (selectDates.dates.from && isBeforeDay(day, selectDates.dates.from))
          ? WAY_LEAVE.toLowerCase()
          : WAY_RETURN.toLowerCase();
      const year = day.year();
      const month = day.month();
      const date = day.date();
      // Calculate the flexible days range
      const flexibleRange = getFlexibleDaysRange(numberOfDays);
      const fromDate = selectDates.dates.from;
      const toDate = selectDates.dates.to;
      // Determine if the current day should be treated as disabled
      const isDisabled = isDateDisabled(day, fromDate, toDate, flexibleRange, focusedInput, calendarOption);

      return isDisabled
        ? false
        : highlights[year] &&
            highlights[year][month] &&
            highlights[year][month][date] &&
            highlights[year][month][date][way];
    };
  }

  isDayBlockedFactory() {
    return (day) => {
      const { websiteId, selectDates, numberOfDays, calendarOption } = this.props;
      const { focusedInput } = this.state;
      const { availableDates } = selectDates.airlineCalendar;
      const website = Object.values(websiteIds).find((el) => el.ID === websiteId);
      const isWebsiteDayBlocked = website && website.calendarDaysBlocked;

      const fromDate = selectDates.dates.from;
      const flexibleRange = getFlexibleDaysRange(numberOfDays);
      const toDate = selectDates.dates.to;
      const way =
        focusedInput === 'startDate' || (selectDates.dates.from && isBeforeDay(day, selectDates.dates.from))
          ? WAY_LEAVE.toLowerCase()
          : WAY_RETURN.toLowerCase();
      const year = day.year();
      const month = day.month();
      const date = day.date();

      // Determine if the current day should be treated as disabled
      const isDisabled = isDateDisabled(day, fromDate, toDate, flexibleRange, focusedInput, calendarOption);

      if (isDisabled) {
        return isDisabled;
      }

      if (!isWebsiteDayBlocked || _isEmpty(availableDates)) {
        return false;
      }

      const isDateAvailable =
        availableDates[year] &&
        availableDates[year][month] &&
        availableDates[year][month][date] &&
        availableDates[year][month][date][way];

      return !isDateAvailable;
    };
  }

  getLastDate(highlights) {
    if (_isEmpty(highlights)) {
      return;
    }

    const years = Object.keys(highlights);
    const lastYear = years[years.length - 1];
    const months = Object.keys(highlights[lastYear]);
    let lastMonth = months[months.length - 1];
    const days = Object.keys(highlights[lastYear][lastMonth]);
    let lastDay = days[days.length - 1];
    lastMonth = parseInt(lastMonth) + 1;
    lastMonth = lastMonth + '';

    if (lastMonth.length === 1) {
      lastMonth = '0' + lastMonth;
    }

    if (lastDay.length === 1) {
      lastDay = '0' + lastDay;
    }

    return moment(`${lastYear}-${lastMonth}-${lastDay}`).add(1, 'days');
  }

  renderDayContentsFactory() {
    return (day) => {
      const { selectDates, calendarOption, numberOfDays, renderDayContents } = this.props;
      const { from, to, flexibleDates } = selectDates.dates;

      const flexibleRange = getFlexibleDaysRange(numberOfDays);

      let isFlexible;
      let daysDiff = 0;

      if (flexibleDates && from !== null) {
        // react-dates doesn't return consistent times with it's date objects
        const hour = from.hour();
        day = day.hour(hour).minute(0).second(0);

        daysDiff = Math.ceil(Math.abs(moment.duration(from.diff(day)).asDays()));
        if (daysDiff === 1 || daysDiff === 2) {
          isFlexible = 'flexible_calendar';
        }
      }

      if (flexibleDates && to !== null) {
        daysDiff = Math.ceil(Math.abs(moment.duration(to.diff(day)).asDays()));
        if (daysDiff === 1 || daysDiff === 2) {
          isFlexible = 'flexible_calendar';
        }
      }

      const isValidButBlocked =
        calendarOption === PERIOD_CALENDAR &&
        this.state.focusedInput === 'endDate' &&
        !day.isSame(selectDates.dates.from) &&
        day.isBetween(selectDates.dates.from, moment(selectDates.dates.from).add(flexibleRange, 'days'));

      return renderDayContents ? (
        renderDayContents(day)
      ) : (
        <CalendarDay isDisabled={isValidButBlocked} className={isFlexible}>
          {isValidButBlocked && <CrossLine />}
          {day.format('D')}
        </CalendarDay>
      );
    };
  }

  initialVisibleMonth(matches) {
    return () => {
      const { selectDates } = this.props;
      const { focusedInput, firstVisibleMonth } = this.state;

      if (matches) {
        return moment();
      }

      switch (focusedInput) {
        case 'startDate':
          return selectDates.dates.from || selectDates.initialVisibleMonth.leave || moment();

        case 'endDate':
          return selectDates.dates.from
            ? selectDates.dates.from || firstVisibleMonth
            : selectDates.initialVisibleMonth.return || moment();

        default:
          return moment();
      }
    };
  }

  render() {
    const { focusedInput, translations, minDate, maxDate } = this.state;

    const {
      selectDates,
      selectDatesInputIds = { startDateId: 'selectDatesStartDateId', endDateId: 'selectDatesEndDateId' },
      tooltipIsVisible,
      isFrontpage,
      isHotelPage,
      hideCalendarInfo,
      companyShortName,
      airlineWhitelabel,
      priceDaysLoading,
      calendarPriceInfo,
      calendarOption,
      thirdPartyHeaderHeight,
      headerHeight,
    } = this.props;

    const { flexibleDates, from, to } = selectDates.dates;

    return (
      <DateRangePickerWrapperComponent
        airlineWhitelabel={airlineWhitelabel}
        isFrontpage={isFrontpage}
        isHotelPage={isHotelPage}
        hideCalendarInfo={hideCalendarInfo}
        tooltipIsVisible={tooltipIsVisible}
        initialVisibleMonth={this.initialVisibleMonth}
        selectDatesInputIds={selectDatesInputIds}
        from={from}
        to={to}
        flexibleDates={flexibleDates}
        flexibleDatesDisabled={selectDates.flexibleDatesDisabled}
        focusedInput={focusedInput}
        translations={translations}
        onDatesChange={this.onDatesChange}
        onFocusChange={this.onFocusChange}
        isDayHighlighted={this.isDayHighlighted}
        isDayBlocked={this.isDayBlocked}
        storeFirstVisibleMonth={this.storeFirstVisibleMonth}
        toggleFlexibleDates={this.props.toggleFlexibleDates}
        companyShortName={companyShortName}
        minDate={minDate}
        maxDate={maxDate}
        renderDayContents={this.renderDayContents}
        isLoading={priceDaysLoading}
        calendarPriceInfo={calendarPriceInfo}
        calendarOption={calendarOption}
        dateRangePickerRef={this.dateRangePickerRef}
        handleCloseButtonClick={this.handleCloseButtonClick}
        thirdPartyHeaderHeight={thirdPartyHeaderHeight}
        headerHeight={headerHeight}
        resetInputFocus={this.resetInputFocus}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    companyShortName: state.settings.value.company.shortName,
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    websiteId: state.settings.value.websiteId,
    selectedOrigins: state.selectOrigins.selectedOrigins,
    selectedDestinations: state.selectDestinations.selectedDestinations,
    calendarOption: state.selectDates.calendarOption,
    thirdPartyHeaderHeight: state.refs.thirdPartyHeaderHeight,
    headerHeight: state.refs.headerHeight,
    numberOfDays: state.searchResultsFilters.numberOfDays,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMissingValuesForInput: function () {
      dispatch(searchConditionsActions.setMissingValuesForInput('selectDates', false));
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withContext()(DateRangePickerWrapper)));
