import React, { Component } from 'react';
import { connect } from 'react-redux';
import { evalIsSearchAllowed } from 'state/searchConditions/operations';
import { setInputsHidden } from 'state/searchContainer/actions';
import SearchButtonComponent from './SearchButtonComponent';
import { setUserInformed } from 'state/flexibleDatesInformer/actions';
import executeTrackingEvent from 'managers/gtmEventTracking/executeTrackingEvent';
import { CLICKED_SEARCH } from 'managers/gtmEventTracking/constants/eventsNames';
import { resetThirdPartyHeaderHeight } from 'utils/thirdParty/resetThirdPartyHeaderHeight';
import SearchButtonComponentFrontPage from './SearchButtonComponent.Frontpage';
import { getSearchButtonMessageContent } from './utils/getSearchButtonMessageContent';
import { setActiveAction } from 'state/actionBar/actions';
import resetSearchResultsFiltersAndFetchSearchResults from 'state/searchResults/operations/resetSearchResultsFiltersAndFetchSearchResults';

class SearchButton extends Component {
  constructor(props) {
    super(props);

    this.handleOnClickSearch = this.handleOnClickSearch.bind(this);
    this.handleOnClickNewSearch = this.handleOnClickNewSearch.bind(this);
  }

  handleOnClickSearch() {
    const {
      evalIsSearchAllowed,
      informUserFlexibleDates,
      executeTrackingEvent,
      calendarOption,
      isHolidayPirates,
      isFrontpage,
      setActiveAction,
      resetSearchResultsFiltersAndFetchSearchResults,
    } = this.props;

    informUserFlexibleDates();
    setActiveAction(null);

    !isFrontpage && isHolidayPirates && resetThirdPartyHeaderHeight();

    const searchIsAllowed = evalIsSearchAllowed();
    if (searchIsAllowed) {
      executeTrackingEvent(CLICKED_SEARCH, { eventValue: calendarOption });

      resetSearchResultsFiltersAndFetchSearchResults();
    }
  }

  handleOnClickNewSearch() {
    const { setInputsHidden } = this.props;

    setInputsHidden(false);
  }

  render() {
    const {
      isFrontpage,
      isHotelPage,
      isSearchPage,
      airlineWhitelabel,
      inputsHidden,
      onClickClose,
      content,
      styles,
      className,
      disabled,
      searchResultsLoader,
      isFetchingSearchResults,
    } = this.props;

    if (isFrontpage) {
      return (
        <SearchButtonComponentFrontPage
          onClick={this.handleOnClickSearch}
          airlineWhitelabel={airlineWhitelabel}
          id='e8f2d917'
        />
      );
    }
    const onClick = inputsHidden ? this.handleOnClickNewSearch : this.handleOnClickSearch;
    const message = getSearchButtonMessageContent(content, inputsHidden, isSearchPage, isHotelPage);

    return (
      <SearchButtonComponent
        messageSearch={message}
        onClickSearch={onClick}
        onClickClose={onClickClose}
        isAirBaltic={airlineWhitelabel && airlineWhitelabel.isAirBaltic}
        styles={styles}
        className={className}
        disabled={disabled || searchResultsLoader || isFetchingSearchResults}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    airlineWhitelabel: state.settings.value.airlineWhitelabel,
    calendarOption: state.selectDates.calendarOption,
    searchResultsLoader: state.searchResults.showLoader,
    isFetchingSearchResults: state.searchResults.isFetchingSearchResults,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setInputsHidden(value) {
      dispatch(setInputsHidden(value));
    },
    evalIsSearchAllowed() {
      return dispatch(evalIsSearchAllowed());
    },
    informUserFlexibleDates() {
      dispatch(setUserInformed(false));
    },
    executeTrackingEvent(eventName, eventData) {
      dispatch(executeTrackingEvent(eventName, eventData));
    },
    setActiveAction(action) {
      dispatch(setActiveAction(action));
    },
    resetSearchResultsFiltersAndFetchSearchResults() {
      dispatch(resetSearchResultsFiltersAndFetchSearchResults());
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchButton);
